<template>
  <main class="icon">
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4414 6L7.44141 11L2.44141 6"
        :stroke="getColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </main>
</template>

<script>
export default {
  props: ["strokeColor"], //always a string
  data() {
    return {
      statusList: [
        { name: "Ongoing", color: "#0044b9" },
        { name: "Upcoming", color: "#ffc400" },
        { name: "Awaiting Verification", color: "#c973ff" },
        { name: "Completed", color: "#027a48" },
      ],
    };
  },
  computed: {
    getColor() {
      return (
        this.statusList.find((status) => status.name === this.strokeColor)
          .color || "var(--primary)"
      );
    },
  },
};
</script>

<style scoped>
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
